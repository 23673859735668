@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
.App {
  font-family: sans-serif;
  text-align: center;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

